var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "marketplace-filters filters-container filters table"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "filters__container"
  }, [_c('div', {
    staticClass: "filters__fields"
  }, [_c('portal', {
    attrs: {
      "to": "gnPageHeadingActionsLeft"
    }
  }, [_vm.showLePicker ? _c('sygni-select', {
    staticClass: "input-only w-300",
    attrs: {
      "legacyStyling": false,
      "options": _vm.options,
      "value": _vm.viewedId
    },
    on: {
      "input": _vm.setViewedId
    }
  }) : _vm._e()], 1), _c('GenproxField', {
    staticClass: "search",
    attrs: {
      "componentId": "searchInput",
      "icon": _vm.MagnyfyingGlassIcon,
      "placeholder": "Search"
    },
    on: {
      "submit": function submit($event) {
        return _vm.handleSearch($event, ['code']);
      }
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Transaction date",
      "componentId": "transactionDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.date.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.date, "value", $$v);
      },
      expression: "filters.date.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Registration status",
      "options": [{
        label: 'Registered',
        value: 'REGISTERED'
      }, {
        label: 'Unknown',
        value: 'UNKNOWN'
      }, {
        label: 'Unregistered',
        value: 'UNREGISTERED'
      }],
      "componentId": "registrationStatus",
      "type": "select"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.registrationStatus.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.registrationStatus, "value", $$v);
      },
      expression: "filters.registrationStatus.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Registration date",
      "componentId": "registrationDate",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.registrationDate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.registrationDate, "value", $$v);
      },
      expression: "filters.registrationDate.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Created date",
      "componentId": "createdAt",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.createdAt.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.createdAt, "value", $$v);
      },
      expression: "filters.createdAt.value"
    }
  }), _c('GenproxField', {
    attrs: {
      "label": "Updated date",
      "componentId": "updatedAt",
      "type": "dateRange"
    },
    on: {
      "submit": _vm.applyFilters
    },
    model: {
      value: _vm.filters.updatedAt.value,
      callback: function callback($$v) {
        _vm.$set(_vm.filters.updatedAt, "value", $$v);
      },
      expression: "filters.updatedAt.value"
    }
  })], 1), _c('div', {
    staticClass: "filters__clear-wrapper"
  }, [_c('a', {
    class: ['filters__clear gn-link', _vm.filterQuery || _vm.orFilterQuery ? 'enabled' : ''],
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.clearAll.apply(null, arguments);
      }
    }
  }, [_vm._v("Clear all")])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }